<template>
    <div class="home">
        <!-- 头部 -->
        <!-- <largeHead :tabArrShow="tabArrShow"></largeHead> -->

        <!-- 主体内容 -->
        <div class="brandDead">
            <div class="son">
                <div class="title">分类</div>
                <div style="display: flex;flex-wrap: wrap;row-gap: 10px;column-gap: 20px;">
                    <div class="tabs cur" :class="[qbFLShow ? 'tabsAct' : '']" @click="qbFL">全部</div>
                    <div class="tabs" style="display: flex;flex-wrap: wrap;" v-for="(i, index) in goodsArrNav" :key="index">
                        <div class="tab" :class="[i.isShow ? 'tabsAct' : '']" @click="tabsClick(index, i)">{{ i.typeName }}
                        </div>
                    </div>
                </div>

            </div>
            <div class="son">
                <div class="title">品牌</div>
                <div class="tabImg" v-for="(i, index) in brandListPage.slice(0, 7)" :key="index" @click="searchClick(i)">
                    <div class="img">
                        <div>
                            <img :src="i.logo" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="listBrand" v-loading="loading">
            <div class="son" v-for="(i, index) in brandList" :key="index" @click="searchClick(i)">
                <div class="img">
                    <img :src="i.logo" alt="">
                </div>
                <div class="text">{{ i.brandName }}</div>
            </div>
            <div v-if="brandList.length == 0"
                style="width: 100%;text-align: center;margin: 50px auto;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
                <img src="@/assets/wsj.png" style="width: 250px;object-fit: contain;" alt="">
                <div style="width: 100%;margin-top: 10px;">暂无数据</div>
            </div>
        </div>

        <!-- 分页 -->
        <!-- <div class="paging">
            <el-pagination background layout="prev, pager, next" :total="100">
            </el-pagination>
        </div> -->

        <paging :total="shoppingListTotal" @handleCurrentChange="handleCurrentChange" :page-size="50"></paging>



        <!-- 底部 -->
        <!-- <bottomContent></bottomContent> -->
    </div>
</template>

<script>
import largeHead from "@/components/largeHead.vue";
import bottomContent from "@/components/bottomContent.vue";
import paging from "@/components/paging.vue";
import { getclassificationOfBrand } from "@/utils/api/headApi/index"
export default {
    name: 'brandCenter',
    components: {
        largeHead,
        bottomContent,
        paging
    },
    data() {
        return {
            loading:true,
            // 头部激活菜单
            tabArrShow: 2,
            // 品牌列表
            brandList: [],
            // 分页
            shoppingListTotal: 0,
            current: 1,
            // 一级分类
            goodsArrNav: [],
            // 分页1的数据
            brandListPage: [],
            // 类型
            brandType: '',
            // 分类id
            typeId: '',
            qbFLShow:true
        };
    },
    watch: {
        '$store.state.goodsArrNav'(newVal, oldVal) {
            this.goodsArrNav = this.$store.state.goodsArrNav
            this.goodsArrNav.map((t) => (t.isShow = false));
        },
    },
    mounted() {
        this.getBrand()
        this.goodsArrNav = this.$store.state.goodsArrNav
        this.goodsArrNav.map((t) => (t.isShow = false));
    },
    methods: {
        tabsClick(index, i) {
            this.loading = true
            this.current = 1
            this.goodsArrNav.map((t) => (t.isShow = false));
            this.qbFLShow = false
            this.goodsArrNav[index].isShow = true;
            console.log(i.id)
            this.typeId = i.id
            this.getBrand(i.id)
        },
        qbFL(){
            this.qbFLShow = true
            this.loading = true
            this.current = 1
            this.goodsArrNav.map((t) => (t.isShow = false));
            this.typeId = ''
            this.getBrand(this.typeId)
        },
        // 获取品牌列表
        async getBrand(id) {
            const res = await getclassificationOfBrand({ size: 50, current: this.current, typeId: id || '', isBrand: false })
            if (res.statusCode == 8201) {
                if (this.current == 1) {
                    this.brandListPage = res.data.records
                }
                this.brandList = res.data.records
                this.shoppingListTotal = res.data.total
                this.loading = false
                console.log(this.brandList)
            }
        },
        // 分页
        handleCurrentChange(val) {
            this.loading = true
            console.log(val)
            this.current = val
            this.getBrand(this.typeId)
        },
        // 根据品牌搜索商品
        searchClick(i) {
            // console.log(i)
            window.open(`#/search2?keyWords=${i.brandName}&type=brand`, '_blank');
        },
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    // 删选样式
    >.brandDead {
        width: 1200px;
        height: auto;
        margin: 20px auto;
        background-color: #fff;
        padding: 20px;
        box-sizing: border-box;

        >.son {
            width: 100%;
            height: auto;
            font-size: 14px;
            display: flex;
            padding-bottom: 15px;
            border-bottom: 1px solid #F5F5F5;
            margin-top: 12px;
            align-items: center;

            >.title {
                width: 65px;
                color: #666;
                margin-right: 20px;
            }

            .tabs {
                color: #333;
                display: flex;

                >.tab {
                    // margin-right: 20px;
                    transition: all .3s;
                    cursor: pointer;
                }

                >.tab:hover {
                    color: #FF4242;
                    transition: all .3s;
                }
            }

            >.tabImg {
                height: auto;
                display: flex;

                >.img {
                    width: 130px;
                    height: 50px;
                    padding: 10px 25px;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    cursor: pointer;
                    border: 1px solid #E0E0E0;
                    margin-right: 20px;
                    transition: all .3s;

                    >div {
                        width: 80px;
                        height: 30px;

                        >img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                >.img:hover {
                    border: 1px solid #FF4242;
                    transition: all .3s;
                }
            }
        }
    }

    // 内容
    .listBrand {
        width: 1200px;
        height: auto;
        margin: 20px auto;
        display: flex;
        // justify-content: space-around;
        gap: 10px;
        flex-wrap: wrap;

        >.son {
            width: 230px;
            height: 120px;
            background-color: #fff;
            border-radius: 1px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 15px 0;
            box-sizing: border-box;
            margin-bottom: 15px;
            cursor: pointer;
            border: 1px solid #fff;
            transition: all .3s;

            >.img {
                width: 130px;
                height: 72px;
                display: flex;
                align-items: center;

                >img {
                    width: 100%;
                    height: auto;
                }
            }

            >.text {
                font-size: 16px;
                color: #333;
            }
        }

        >.son:hover {
            border: 1px solid #FF4242;
            transition: all .3s;
        }
    }

    // 分页
    .paging {
        width: 1200px;
        height: 50px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 50px;
    }
}

.tabsAct {
    color: #ff4242 !important;
    font-weight: 700 !important;
    transition: all 0.3s;
}

// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}
</style>